<template>
  <v-container fluid>
    <AppTitleBar>
      <template v-slot:title>{{ $t(`labels.users`) }}</template>
      <template slot="actions">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="create" v-on="on">
              <v-icon small v-text="$vuetify.icons.values.add" />
            </v-btn>
          </template>
          <span>{{ $t('new') }}</span>
        </v-tooltip>
      </template>
    </AppTitleBar>
    <v-col cols="12" sm6 md4 lg3 xl3 offset-sm6 offset-md8 offset-lg9 offset-xl9>
      <SingleSearchFilter :context="context" :fields="searchFields" />
    </v-col>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filtered"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          must-sort
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          :server-items-length="tableItemsCount"
          item-key="name"
          class="elevation-1"
        >
          <template v-slot:[`item.user.active`]="{ item }">
            <v-checkbox height="12" v-model="item.account.active" />
          </template>
          <template v-slot:[`item.display`]="{ item }">
            <span :class="{ disabled: !item.account.active, heading: true }">{{ item.display }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left min-width="150">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small>
                  <v-icon small v-text="$vuetify.icons.values.options" />
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editProfile(item)">
                  <v-list-item-title>{{ $t('labels.profile') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="editAccount(item)">
                  <v-list-item-title>{{ $t('labels.account') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="editPassword(item)">
                  <v-list-item-title>{{ $t('labels.password') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="disable(item)" v-if="item.account.active">
                  <v-list-item-title>{{ $t('labels.disable') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="enable(item)" v-else>
                  <v-list-item-title>{{ $t('labels.enable') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="destroy(item)">
                  <v-list-item-title>{{ $t('labels.delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { create } from 'vue-modal-dialogs'
import { AppTitleBar, SingleSearchFilter } from '@argon/app/components'
import { Datatable } from '@argon/app/mixins'
import { ConfirmDeleteUserDialog, ConfirmDisableUserDialog, ConfirmEnableUserDialog } from '@argon/iam/users/components'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE } from '@argon/iam/users/store'

const confirmDeleteAction = create(ConfirmDeleteUserDialog)
const confirmDisableAction = create(ConfirmDisableUserDialog)
const confirmEnableAction = create(ConfirmEnableUserDialog)

export default {
  name: 'SystemUsers',
  mixins: [Datatable],
  components: {
    AppTitleBar,
    SingleSearchFilter
  },
  data() {
    return {
      filterString: null,
      headers: [
        { text: '', value: 'user.active', sortable: false },
        { text: this.$t('labels.displayName'), value: 'user.display' },
        { text: this.$t('labels.firstname'), value: 'user.firstname' },
        { text: this.$t('labels.lastname'), value: 'user.lastname' },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ],
      loading: true,
      context: NAMESPACE
    }
  },
  computed: {
    ...mapState(ME_NAMESPACE, ['profile']),
    ...mapState(NAMESPACE, ['users', 'tableItemsCount']),
    filtered() {
      return this.users.filter((x) => x.user.refid !== this.profile.refid)
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listUsers', 'clearUsers', 'deleteUser', 'patchAccount']),
    editProfile(item) {
      this.$router.push({ name: 'systemUserProfile', params: { userId: item.user_id } })
    },
    editAccount(item) {
      this.$router.push({ name: 'systemUserAccount', params: { userId: item.user_id } })
    },
    editPassword(item) {
      this.$router.push({ name: 'systemUserPassword', params: { userId: item.user_id } })
    },
    create() {
      this.$router.push({ name: 'systemNewUser' })
    },
    disable(item) {
      confirmDisableAction().then((res) => {
        if (res) {
          this.patchAccount({ userId: item.user_id, data: { active: false } }).then(() => {
            this.fetchData()
          })
        }
      })
    },
    enable(item) {
      confirmEnableAction().then((res) => {
        if (res) {
          this.patchAccount({ userId: item.user_id, data: { active: true } }).then(() => {
            this.fetchData()
          })
        }
      })
    },
    destroy(item) {
      confirmDeleteAction().then((res) => {
        if (res) {
          this.deleteUser(item.user_id)
        }
      })
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.listUsers({ pager: this.pager })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.loading = false
          })
      })
    }
  },

  created() {
    this.fetchData()
  },
  destroyed() {
    this.clearUsers()
  }
}
</script>
<style scoped>
span.disabled {
  text-decoration: line-through;
}
</style>
