var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('AppTitleBar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.users")))]},proxy:true}])},[_c('template',{slot:"actions"},[_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.create}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.add)}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('new')))])])],1)],2),_c('v-col',{attrs:{"cols":"12","sm6":"","md4":"","lg3":"","xl3":"","offset-sm6":"","offset-md8":"","offset-lg9":"","offset-xl9":""}},[_c('SingleSearchFilter',{attrs:{"context":_vm.context,"fields":_vm.searchFields}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray },"server-items-length":_vm.tableItemsCount,"item-key":"name"},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.user.active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"height":"12"},model:{value:(item.account.active),callback:function ($$v) {_vm.$set(item.account, "active", $$v)},expression:"item.account.active"}})]}},{key:"item.display",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ disabled: !item.account.active, heading: true }},[_vm._v(_vm._s(item.display))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values.options)}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editProfile(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.profile')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.editAccount(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.account')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.editPassword(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.password')))])],1),(item.account.active)?_c('v-list-item',{on:{"click":function($event){return _vm.disable(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.disable')))])],1):_c('v-list-item',{on:{"click":function($event){return _vm.enable(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.enable')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.destroy(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('labels.delete')))])],1)],1)],1)]}}],null,true)})],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }